<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import TransitionContainer from "@/components/TransitionContainer";

    export default {
        name: "ModalStatusOrderHistory",
        components: {
            TheSvgIcon,
            TransitionContainer,
        },
        props: {
            history: Array,
        },
        data() {
            return {
                isOpened: false,
            };
        },
    };
</script>

<template>
    <div class="modal-status-order-history">
        <button
            class="modal-status-order-history__button outline"
            :class="{ 'is-opened': isOpened }"
            @click="isOpened = !isOpened"
        >
            <span class="modal-status-order-history__icon">
                <img
                    :src="`/static/${this.$root.$_name}/status-order/order-history.svg`"
                    alt=""
                />
            </span>
            История обработки заказа
            <the-svg-icon
                class="modal-status-order-history__arrow"
                name="arrow"
                :size-w="12"
            ></the-svg-icon>
        </button>
        <transition-container :show="isOpened">
            <div class="modal-status-order-history__table">
                <div
                    class="modal-status-order-history__row"
                    v-for="(record, index) in history"
                    :key="index"
                >
                    <div class="modal-status-order-history__cell">{{ record.date }}</div>
                    <div class="modal-status-order-history__cell">
                        {{ record.status.name }}
                    </div>
                </div>
            </div>
        </transition-container>
    </div>
</template>

<style lang="scss">
    .modal-status-order-history__button {
        position: relative;
        display: flex;
        align-items: center;
        color: $status-link-color;
        text-align: left;
        &.is-opened {
            .modal-status-order-history__arrow {
                transform: rotate(90deg);
            }
        }
    }

    .modal-status-order-history__icon {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 20px;
    }

    .modal-status-order-history__arrow {
        margin-left: 3px;
        transform: rotate(-90deg);
        transition: transform 0.2s ease-in;
        vertical-align: middle;
    }

    .modal-status-order-history__table {
        padding-top: 16px;
        color: $status-subtitle-color;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
    }

    .modal-status-order-history__row {
        display: flex;
        padding: 12px 0;
        border-top: 1px solid var(--input-border-color);
        &:last-child {
            padding-bottom: 0;
        }
    }

    .modal-status-order-history__cell {
        &:first-child {
            width: 35%;
        }
        &:last-child {
            width: 65%;
            text-align: right;
        }
    }
</style>
