<script>
    export default {
        name: "StatusOrderModelProgress",
        props: {
            progress: String,
        },
    };
</script>

<template>
    <div class="b-status__success">
        <div class="b-status__progress">
            <div
                class="b-status__progress-item"
                :class="{
                    'b-status__progress-item--active':
                        progress === 'in_processing' ||
                        progress === 'finish' ||
                        progress === 'in_delivery' ||
                        progress === 'sent',
                }"
            >
                <div class="b-status__progress-box">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2a1 1 0 011 1v3a1 1 0 01-2 0V3a1 1 0 011-1zm0 15a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm10-5a1 1 0 01-1 1h-3a1 1 0 010-2h3a1 1 0 011 1zM7 12a1 1 0 01-1 1H3a1 1 0 010-2h3a1 1 0 011 1zm12.071 7.071a1 1 0 01-1.414 0l-2.121-2.121a1 1 0 011.414-1.414l2.121 2.12a1.001 1.001 0 010 1.415zM8.464 8.464a1 1 0 01-1.414 0l-2.12-2.12a1 1 0 011.414-1.415l2.12 2.121a1 1 0 010 1.414zM4.93 19.071a1 1 0 010-1.414l2.121-2.121a1 1 0 111.414 1.414l-2.12 2.121a1 1 0 01-1.415 0zM15.536 8.464a1 1 0 010-1.414l2.12-2.121a1 1 0 111.415 1.414L16.95 8.464a1 1 0 01-1.414 0z"
                        />
                    </svg>
                </div>
                Обработка
            </div>
            <div
                class="b-status__progress-item"
                :class="{
                    'b-status__progress-item--active':
                        progress === 'finish' ||
                        progress === 'in_delivery' ||
                        progress === 'sent',
                }"
            >
                <div class="b-status__progress-box">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.447 15.895a1 1 0 10-.894-1.79l-3 1.5a1 1 0 00.894 1.79l3-1.5z"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.447 1.116a.92.92 0 00-.894 0l-9 4.95C2.214 6.252 2 6.633 2 7.05v9.9c0 .417.214.797.553.984l9 4.95a.92.92 0 00.894 0l9-4.95c.339-.187.553-.567.553-.984v-9.9c0-.417-.214-.798-.553-.984l-9-4.95zM11 12.68L4 8.83v7.44l7 3.85v-7.44zm9 3.59l-7 3.85v-7.44l7-3.85v7.44zm-1.236-9.22L12 10.77 5.236 7.05 12 3.33l6.764 3.72z"
                        />
                    </svg>
                </div>
                Отправлен
            </div>
            <div
                class="b-status__progress-item"
                :class="{
                    'b-status__progress-item--active':
                        progress === 'finish' || progress === 'in_delivery',
                }"
            >
                <div class="b-status__progress-box">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.44 2.525a2.1 2.1 0 00-.935.22l-1.848.912-.005.003a2.1 2.1 0 00-.33 3.55l1.3.987-2.479 1.252-1.462-1.102a1 1 0 00-1.005-.117l-2.1.925a2.1 2.1 0 00-.472 3.493l.003.002 3.992 3.398.002.001a3.191 3.191 0 003.493.436l2.176-1.082v3.967a2.085 2.085 0 003.014 1.887h.001l1.88-.932a2.1 2.1 0 001.108-1.393l1.761-7.382 3.022-1.5a3.746 3.746 0 00-.474-6.902 3.744 3.744 0 00-2.86.197l-2.834 1.432-4.005-2.028-.006-.003a2.1 2.1 0 00-.937-.22zm5.326 4.302a.998.998 0 00.15-.076l3.197-1.616h.003a1.744 1.744 0 011.55 3.125l-3.44 1.706a1 1 0 00-.529.664l-1.87 7.837a.1.1 0 01-.052.067l-1.88.932-.002.001a.084.084 0 01-.083-.004.084.084 0 01-.04-.073v-5.601a1 1 0 00-1.445-.895l-3.62 1.8a1.191 1.191 0 01-1.304-.164l-3.996-3.4a.1.1 0 01.011-.16l1.53-.673 1.49 1.124a1 1 0 001.053.095l4.442-2.246.041-.02 4.794-2.423zm-2.594-.93l-2.689-1.362a.1.1 0 00-.088 0l-.003.003-1.845.91a.1.1 0 00-.015.169l2.075 1.576 2.565-1.296z"
                        />
                    </svg>
                </div>
                В пути
            </div>
            <div
                class="b-status__progress-item"
                :class="{ 'b-status__progress-item--active': progress === 'finish' }"
            >
                <div class="b-status__progress-box">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.67 1.957A4.23 4.23 0 0012 1a4.308 4.308 0 00-2.687.952 4.168 4.168 0 00-1.46 2.418 1 1 0 001.961.39c.098-.49.366-.934.762-1.256.395-.322.893-.5 1.41-.504a2.26 2.26 0 011.419.507c.397.324.665.772.76 1.265a1 1 0 101.964-.382 4.17 4.17 0 00-1.46-2.433zM15.453 12.6a1 1 0 00-1.6-1.2l-2.828 3.77-1.326-1.324a1 1 0 10-1.414 1.414l2.141 2.14a1 1 0 001.507-.107l3.52-4.693z"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.035 6.066H5.965a2.213 2.213 0 00-1.44.477A2.179 2.179 0 003.727 7.9L2.012 19.648a2.13 2.13 0 00.632 1.734 2.167 2.167 0 001.615.616h15.482a2.179 2.179 0 001.616-.617 2.144 2.144 0 00.64-1.645 1.013 1.013 0 00-.01-.088l-1.714-11.75a2.18 2.18 0 00-.797-1.355 2.213 2.213 0 00-1.44-.477zm.017 2h.04a.213.213 0 01.14.044.18.18 0 01.065.1l1.702 11.66a.133.133 0 01-.041.082.166.166 0 01-.059.037.18.18 0 01-.073.01.988.988 0 00-.06-.001H4.235c-.02 0-.04 0-.06.002a.18.18 0 01-.073-.011.164.164 0 01-.058-.037A.143.143 0 014 19.87L5.703 8.21a.18.18 0 01.065-.1.213.213 0 01.14-.045l.04.001h12.104z"
                        />
                    </svg>
                </div>
                Доставлен
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .b-status__row {
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
            padding-top: 12px;
            margin-top: 12px;
            border-top: 1px solid var(--primary-border);
        }
    }

    .b-status__cell {
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-text--light);
    }

    .b-status__progress {
        display: flex;
        justify-content: space-between;
        margin: 24px -4px 24px;
    }

    .b-status__progress-item {
        margin: 0 4px;
        font-size: 12px;
        line-height: 16px;
        color: var(--primary-text--light);
        text-align: center;
        transition: color 0.2s ease-in;
        @include bp($bp-mobile-sm) {
            font-size: 13px;
        }
    }

    .b-status__progress-box {
        display: flex;
        align-items: center;
        justify-content: center;
        @include box(60px);
        background-color: $status-progress-fill;
        margin: 0 auto 4px;
        border-radius: 50%;
        transition: background-color 0.2s ease-in;

        svg {
            fill: $status-progress-fill-svg--active;
        }
        @include bp($bp-mobile-sm) {
            @include box(68px);
        }
    }

    .b-status__progress-item--active {
        color: var(--primary-text);

        .b-status__progress-box {
            background-color: $status-progress-fill--active;

            svg {
                fill: var(--base-white);
            }
        }
    }
</style>
