<script>
    import TransitionContainer from "@/components/TransitionContainer";
    import ModalStatusOrderHistory from "./ModalStatusOrderHistory";

    export default {
        name: "ModalStatusOrderItem",
        components: {
            ModalStatusOrderHistory,
            TransitionContainer,
        },
        props: {
            order: Object,
            index: Number,
            isOpened: Boolean,
        },
        methods: {
            getIconUrl(name) {
                return `/static/${this.$root.$_name}/status-order/${name}.svg`;
            },
        },
    };
</script>

<template>
    <div class="modal-status-order-item" :class="{ 'is-opened': isOpened }">
        <div class="modal-status-order-item__header">
            <div class="modal-status-order-item__row">
                <div class="modal-status-order-item__icon">
                    <img :src="getIconUrl('order-number')" alt="" />
                </div>
                <div class="modal-status-order-item__title">Номер заказа</div>
                <div class="modal-status-order-item__subtitle">{{ order.number }}</div>
                <button
                    class="modal-status-order-item__button outline"
                    :class="{ 'is-opened': isOpened }"
                    @click="$emit('on-set-active-order', index)"
                ></button>
            </div>
        </div>
        <transition-container :show="isOpened">
            <div class="modal-status-order-item__content">
                <div class="modal-status-order-item__row" v-if="order.deliveryDate">
                    <div class="modal-status-order-item__icon">
                        <img :src="getIconUrl('order-date')" alt="" />
                    </div>
                    <div class="modal-status-order-item__title">
                        Ориентировочная дата доставки
                    </div>
                    <div class="modal-status-order-item__subtitle">
                        {{ order.deliveryDate }}
                    </div>
                </div>
                <div class="modal-status-order-item__row" v-if="order.deliveryCompany">
                    <div class="modal-status-order-item__icon">
                        <img :src="getIconUrl('order-delivery')" alt="" />
                    </div>
                    <div class="modal-status-order-item__title">Служба доставки</div>
                    <div class="modal-status-order-item__subtitle">
                        {{ order.deliveryCompany }}
                    </div>
                </div>
                <div class="modal-status-order-item__row" v-if="order.trackingNumber">
                    <div class="modal-status-order-item__icon">
                        <img :src="getIconUrl('order-tracking')" alt="" />
                    </div>
                    <div class="modal-status-order-item__title">Номер отслеживания</div>
                    <div class="modal-status-order-item__subtitle">
                        {{ order.trackingNumber }}
                    </div>
                </div>
                <div class="modal-status-order-item__history" v-if="order.history.length">
                    <modal-status-order-history
                        :history="order.history"
                    ></modal-status-order-history>
                </div>
            </div>
        </transition-container>
    </div>
</template>

<style lang="scss">
    .modal-status-order-item {
        padding: 16px;
        border: $status-border-width solid $status-border-color;
        border-radius: $status-border-radius;
        transition: border-color 0.2s ease-in;
        &.is-opened {
            border-color: $status-border-color--active;
        }
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        @include bp($bp-desktop-sm) {
            transition: background-color 0.2s ease-in;
            &:hover:not(.is-opened) {
                background-color: $status-item--hover;
            }
        }
    }

    .modal-status-order-item__header {
        position: relative;
    }

    .modal-status-order-item__row {
        position: relative;
        padding-left: 56px;
    }

    .modal-status-order-item__icon {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 36px;
        height: 36px;
    }

    .modal-status-order-item__content {
        .modal-status-order-item__row {
            padding-top: 16px;
        }
    }

    .modal-status-order-item__title {
        color: $status-title-color;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
    }

    .modal-status-order-item__subtitle {
        color: $status-subtitle-color;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }

    .modal-status-order-item__button {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        background-color: #fff;
        border: var(--input-border-width) solid var(--input-border-color);
        border-radius: $status-button-radius;
        &.is-opened {
            &:before {
                transform: translateX(-50%) rotate(90deg);
                opacity: 0;
            }
            &:after {
                transform: translateX(-50%) rotate(0);
            }
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 1px);
            width: 12px;
            height: 2px;
            background-color: $status-button-fill;
            transform: translateX(-50%);
            transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        }
        &:after {
            transform: translateX(-50%) rotate(-90deg) translateZ(0);
        }
    }

    .modal-status-order-item__history {
        margin-top: 16px;
    }
</style>
