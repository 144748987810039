<script>
    import BaseInput from "@frontend/base-input";

    export default {
        name: "BaseInputPhone",
        components: { BaseInput },
        props: {
            value: String,
            errorText: String,
            placeholder: {
                type: String,
                default: "Телефон",
            },
        },
        data() {
            return {
                focused: false,
                error: this.errorText,
                currentValue: this.value,
                elementInput: "",
            };
        },
        watch: {
            errorText: {
                handler(error) {
                    this.error = error;
                },
            },
            value: {
                handler(newVal) {
                    this.currentValue = newVal;
                },
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.elementInput = this.$refs.jsInputPhone.$el.querySelector("input");
                if (this.value) {
                    this.updateElement();
                }
            });
        },
        methods: {
            getCursorPos() {
                let input = this.elementInput,
                    r,
                    re,
                    rc,
                    pos;

                if (input.selectionStart) {
                    pos = input.selectionStart;
                } else if (document.selection) {
                    input.focus();

                    r = document.selection.createRange();
                    if (r === null || typeof r === "undefined") {
                        pos = 0;
                    } else {
                        re = input.createTextRange();
                        rc = re.duplicate();
                        re.moveToBookmark(r.getBookmark());
                        rc.setEndPoint("EndToStart", re);

                        pos = rc.text.length;
                    }
                } else {
                    pos = 0;
                }

                return pos;
            },

            updateElement() {
                this.elementInput.value = this.validate(this.elementInput.value);
                this.currentValue = this.elementInput.value;
                this.$emit("input", this.elementInput.value);
            },
            clickElement() {
                if (this.elementInput.value.length <= 2 || this.getCursorPos() <= 2) {
                    this.elementInput.setSelectionRange(2, 2);
                }
            },
            focusElement() {
                this.elementInput.value = this.validate(this.elementInput.value);
                this.currentValue = this.elementInput.value;
                this.focused = true;
            },
            blurElement() {
                if (this.elementInput.value.length === 2) {
                    this.currentValue = "";
                }
                this.focused = false;
            },
            keyupElement() {
                this.error = null;
                this.$emit("error", this.name);
            },
            validate(value) {
                let matrix = "+7 (___) ___-__-__",
                    i = 0,
                    def = matrix.replace(/\D/g, ""),
                    val = value.replace(/\D/g, "");

                if (def.length >= val.length) val = def;

                value = matrix.replace(/./g, function (a) {
                    return /[_\d]/.test(a) && i < val.length
                        ? val.charAt(i++)
                        : i >= val.length
                        ? ""
                        : a;
                });
                return value;
            },
            errorHandler() {
                this.$emit("error");
            },
        },
    };
</script>

<template>
    <base-input
        ref="jsInputPhone"
        v-model="currentValue"
        type="tel"
        name="phone"
        :placeholder="placeholder"
        :error-text="error"
        @focus="focusElement"
        @click="clickElement"
        @keyup="keyupElement"
        @blur="blurElement"
        @input="updateElement"
        @error="errorHandler"
    ></base-input>
</template>

<style lang="scss">
    //@import "sites/xd-design/src/components/ui/BaseInputStyle";
</style>
