<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheModalWrap from "@main/packages/TheModalWrap";
    import BaseButton from "@main/packages/BaseButton";
    import BaseInput from "@main/packages/BaseInput";
    import BaseInputPhone from "@main/packages/BaseInputPhone";
    import StatusOrderModelProgress from "./templates/StatusOrderModelProgress";
    import ModalStatusOrderItem from "./templates/ModalStatusOrderItem";

    export default {
        name: "ModalStatusOrder",
        components: {
            ModalStatusOrderItem,
            TheModalWrap,
            StatusOrderModelProgress,
            BaseButton,
            BaseInput,
            BaseInputPhone,
        },
        props: {
            statusOrderId: String,
        },
        data() {
            return {
                order: this.statusOrderId || "",
                phone: "",
                loading: false,
                errorText: {
                    order: "",
                    phone: "",
                },
                type: "order",
                orders: [],
                orderProgress: "",
                activeIndexes: [0],
            };
        },
        mounted() {
            this.$refs.orderInput.$el.focus();
        },
        methods: {
            resetOrder() {
                this.order = "";
                this.phone = "";
                this.orders = [];
            },
            submit() {
                this.setErrorText();
                if (this.order.length > 1 || this.phone.length > 3) {
                    this.resetErrorText();
                    this.loading = true;

                    this.$store.rootApi
                        .getStatusOrder({ orderId: this.order, phone: this.phone })
                        .then((response) => {
                            this.loading = false;
                            if (response.error) {
                                const key = !this.order ? "phone" : "order";
                                this.errorText[key] = "Заказ с таким номером не найден";
                                return false;
                            } else {
                                response.orders.length
                                    ? (this.orders = response.orders)
                                    : (this.errorText.phone =
                                          "По вашему номеру заказов не найдено");
                                this.orders = response.orders;
                                this.orderProgress = this.orders[0]?.progress;
                            }
                            sendAnalytics.send(sendAnalytics.events.orderStatus);
                        });
                }
            },
            onChangeType() {
                this.resetErrorText();
                this.resetOrder();
            },
            resetErrorText() {
                this.errorText.order = "";
                this.errorText.phone = "";
            },
            setErrorText() {
                this.errorText.order = "Необходимо ввести номер заказа";
                this.errorText.phone = "Необходимо ввести номер телефона";
            },
            toggleActiveOrder(index) {
                if (this.activeIndexes.includes(index)) {
                    const removedIndex = this.activeIndexes.indexOf(index);
                    this.activeIndexes.splice(removedIndex, 1);

                    if (this.activeIndexes.length) {
                        this.orderProgress =
                            this.orders[
                                this.activeIndexes[this.activeIndexes.length - 1]
                            ].progress;
                    } else {
                        this.orderProgress = "";
                    }
                } else {
                    this.activeIndexes.push(index);
                    this.orderProgress = this.orders[index].progress;
                }
            },
        },
    };
</script>

<template>
    <the-modal-wrap title="Статус заказа">
        <status-order-model-progress
            v-if="orders.length"
            :progress="orderProgress"
            @reset-order="resetOrder"
        ></status-order-model-progress>

        <template v-if="orders.length">
            <modal-status-order-item
                v-for="(order, index) in orders"
                :key="order.number"
                :order="order"
                :index="index"
                :is-opened="activeIndexes.includes(index)"
                @on-set-active-order="toggleActiveOrder"
            ></modal-status-order-item>

            <base-button class="status-order-other" size="lg" @click="resetOrder">
                Статус другого заказа
            </base-button>
        </template>

        <div v-else class="status-order-modal">
            <form class="status-order-modal__form" @submit.prevent="submit">
                <div class="status-order-modal__tabs">
                    <label class="status-order-modal__label">
                        <input
                            class="status-order-modal__input visually-hidden"
                            type="radio"
                            name="type"
                            value="order"
                            v-model="type"
                            @change="onChangeType"
                        />
                        <span class="status-order-modal__radio"></span>
                        <span class="status-order-modal__label-text">Номер заказа</span>
                    </label>

                    <label class="status-order-modal__label">
                        <input
                            class="status-order-modal__input"
                            type="radio"
                            name="type"
                            value="phone"
                            v-model="type"
                            @change="onChangeType"
                        />
                        <span class="status-order-modal__radio"></span>
                        <span class="status-order-modal__label-text">Номер телефона</span>
                    </label>
                </div>

                <base-input
                    v-if="type === 'order'"
                    v-model="order"
                    name="order"
                    type="text"
                    placeholder="Введите номер заказа"
                    :error-text="errorText.order"
                    @error="resetErrorText"
                    ref="orderInput"
                ></base-input>

                <base-input-phone
                    v-if="type === 'phone'"
                    :value="phone"
                    placeholder="Введите номер телефона"
                    :error-text="errorText.phone"
                    @input="(e) => (this.phone = e)"
                    @error="resetErrorText"
                ></base-input-phone>
            </form>

            <div class="status-order-modal__control">
                <base-button
                    :variant="$root.$_variant_modal_button || 'v1'"
                    size="lg"
                    :is-loading="loading"
                    @click="submit"
                >
                    Узнать статус заказа
                </base-button>
            </div>
        </div>
    </the-modal-wrap>
</template>

<style lang="scss">
    .status-order-other {
        width: 100%;
    }

    .status-order-modal {
        text-align: center;
    }

    .status-order-modal__form {
        margin-bottom: 16px;
    }

    .status-order-modal__control {
        display: flex;
        justify-content: center;

        button {
            width: 100%;
            //font-size: var(--modal-button-text-size);
            //font-weight: var(--modal-button-text-weight);
            //border-radius: var(--modal-button-radius);
            //background-color: var(--modal-button-fill);
            //border-color: var(--modal-button-fill);
        }
    }

    .status-order-modal__tabs {
        display: flex;
        align-items: center;
    }

    .status-order-modal__label {
        display: flex;
        align-items: center;
        padding-right: 10px;
        cursor: pointer;
    }

    .status-order-modal__label-text {
        margin-left: 5px;
        font-size: 12px;
        text-align: left;
        @include bp($bp-desktop-sm) {
            font-size: 14px;
        }
    }

    .status-order-modal__input {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        @include bp($bp-desktop-sm) {
            &:focus-visible {
                & + .status-order-modal__radio {
                    @include outline;
                }
            }
        }
    }

    .status-order-modal__radio {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        border: 3px solid var(--base-white);
        box-shadow: 0 0 0 var(--input-border-width) var(--input-border-color);
        transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;
    }

    .status-order-modal__input:checked + .status-order-modal__radio {
        background-color: var(--modal-control-fill);
        box-shadow: 0 0 0 var(--input-border-width) var(--input-border-color--active);
    }
</style>
